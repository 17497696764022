@use 'modern-css-reset';

body {
  font-family: on-air-momentum, Arial, sans-serif;
}

:root {
  --zindex-media-banner-base: 0;
  --zindex-sticky-bar: 20;
  --zindex-navigation-base: 50;
  --zindex-shout-out-base: 10;
  --zindex-modal-backdrop: 60;
  --zindex-modal: 61;

  --rail-tile-width: 25%;
  --rail-tile-gap: 0.5em;
  --rail-tile-padding: 1em;
}
